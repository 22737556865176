import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import EVENTBUS_SERVICES from './Services/Eventbus';
import HANGFIRE_SERVICES from './Services/Hangfire';

function GetListItem(service, base) {
  return (
    <Paper elevation={2} sx={{ display:'flex',width: '270px', height:'80px', p:1, m:1}} key={service.name}>
      <ListItem disablePadding>
        <ListItemButton component="a" href={service.url + base}>
          <ListItemText primary={service.name} secondary={service.repo}/>
        </ListItemButton>
      </ListItem>
    </Paper>
  )
}

const App = () => {
    return (
      <Grid container>
        
        <AppBar position="static" >
            <Toolbar variant="regular" >
              <Typography variant="h6" component="div">
               Quattro Dashboard
              </Typography>
            </Toolbar>
        </AppBar>

        <Box sx={{paddingY: 3, width: 1}}>
          <Typography variant="h6" component="div" sx={{paddingX: 3}}>Eventbus</Typography>
          <Box sx={{ width: '100%', display:'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent:'flex-start',  padding: 1, margin: 0}}>
            { EVENTBUS_SERVICES.map(s => GetListItem(s, '/eventbus')) }
          </Box>
        </Box>

        <Box sx={{paddingBottom: 3, width: 1}}>
          <Typography variant="h6" component="div" sx={{paddingX: 3}}>Hangfire</Typography>
          <Box sx={{ width: '100%', display:'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent:'flex-start',  padding: 1, margin: 0}}>
            { HANGFIRE_SERVICES.map(s => GetListItem(s, '/hangfire')) }
          </Box>
        </Box>

      </Grid>
    )
}
export default App