const EVENTBUS_SERVICES = [
  {
    url: "registration",
    name: "Registration Service",
    repo: "Quattro.Asset.Service.Registration",
  },
  {
    url: "breakdown",
    name: "Breakdown Service",
    repo: "Quattro.Asset.Service.Breakdown",
  },
  {
    url: "user",
    name: "User Service",
    repo: "Quattro.Auth.Service.User",
  },
  {
    url: "sso",
    name: "SSO",
    repo: "SingleSignOn",
  },
  {
    url: "location",
    name: "Location Service",
    repo: "Location.Service",
  },
  {
    url: "protection",
    name: "Protection",
    repo: "Quattro.Asset.Service.Protection"
  },
  {
    url: "taskmanagement",
    name: "Task Management",
    repo: "Quattro.Shared.Service.TaskManagement"
  },
  {
    url: "rtls",
    name: "RTLS",
    repo: "Quattro.Service.Rtls"
  },
  {
    url: "flow",
    name: "Flow",
    repo: "Quattro.Service.Flow"
  },
  {
    url: "notification",
    name: "Notification",
    repo: "Quattro.Service.Notification"
  },
  {
    url: "integration",
    name: "Integration",
    repo: "Quattro.Service.Integration"
  },
  {
    url: "shared",
    name: "Shared",
    repo: "Quattro.Service.Shared"
  },
  {
    url: "patient",
    name: "Patient",
    repo: "Quattro.Patient.Service",
  },
];

export default EVENTBUS_SERVICES;
