const HANGFIRE_SERVICES = [
  {
    url: "user",
    name: "User Service",
    repo: "Quattro.Auth.Service.User",
  },
  {
    url: "registration",
    name: "Registration Service",
    repo: "Quattro.Asset.Service.Registration",
  },
  {
    url: "breakdown",
    name: "Breakdown Service",
    repo: "Quattro.Asset.Service.Breakdown",
  },
  {
    url: "protection",
    name: "Protection Service",
    repo: "Quattro.Asset.Service.Protection",
  },
  {
    url: "importer",
    name: "Importer Service",
    repo: "Quattro.Shared.Service.Importer",
  },
  {
    url: "notification",
    name: "Notification Service",
    repo: "Quattro.Service.Notification"
  },
  {
    url: "listing",
    name: "Listing Service",
    repo: "Quattro.Asset.Service.Listing"
  },
  {
    url: "integration",
    name: "Integration Service",
    repo: "Quattro.Service.Integration"
  },
  {
    url: "shared",
    name: "Shared Service",
    repo: "Quattro.Service.Shared"
  },
  {
    url: "rtls",
    name: "RTLS Service",
    repo: "Quattro.Service.Rtls"
  },
  {
    url: "flow",
    name: "Flow Service",
    repo: "Quattro.Service.Flow"
  },
  {
    url: "patient",
    name: "Patient Service",
    repo: "Quattro.Service.Patient"
  }
];

export default HANGFIRE_SERVICES;
